<script setup>
  import { ref, onMounted, watch } from 'vue'
  import { Format } from '@/format';
  import { useBackend } from '@/composables/Backend';
  import { useCurrentUser } from '@/composables/CurrentUser';
  import { useCartman } from '@/composables/Cartman';
  import PriceIncreaseAlert from '@/components/PriceIncreaseAlert.vue';
  
  const backend = useBackend();
  const currentUser = useCurrentUser();
  const cartman = useCartman();

  const isLoading = ref(false);
  const isAddingToCart = ref(false);
  const loadError = ref('');
  const addToCartError = ref('');
  const quotes = ref([]);

  function loadData() {
    isLoading.value = true;
    backend.getQuoteList()
      .then(quotesResult => {
        if (quotesResult) {
          // Filter quotes to include only those with a status of 'Published'
          quotes.value = quotesResult.quotes.filter(quote => quote.status === 'published');      
        }
      })
      .finally(() => {
        isLoading.value = false;
      });
  }

  function clearData() {
    addToCartError.value = '';
    loadError.value = '';
    quotes.value = [];
  }

  function doAddToCart(quoteId) {
    addToCartError.value = '';
    isAddingToCart.value = true;

    backend.addQuoteToCart(quoteId)
      .then(result => {
        if (result && result.success) {
          // refresh cart data since this endpoint doesn't return the updated cart
          cartman.shouldReload.value = true;

          // show cart          
          cartman.isCartShown.value = true;
        }
        else {
          if (result.error) {
            addToCartError.value = result.error;
          }
          else {
            addToCartError.value = 'An error occurred adding the quote to your cart. Please try again later or contact support.'
          }
        }
      })
      .finally(() => {
        isAddingToCart.value = false; // Reset the button state
      });
  }

  onMounted(() => {
    watch(currentUser.isLoggedIn, (isLoggedIn) => {
      if (isLoggedIn) {
        loadData();
      }
      else {
        clearData();
      }
    });
  });

</script>

<template>  
  <div class="col">
    <price-increase-alert></price-increase-alert> 

    <div v-if="loadError" class="quote--alert-primary" role="alert">
      An error occurred loading quotes. Please try again later or contact support.
    </div>

    <div v-if="addToCartError">{{ addToCartError }}</div>
    <p v-if="isLoading">Loading...</p>

    <div v-if="isLoading == false && quotes.length == 0">
      <p>There aren't any quotes to display.</p>
    </div>

    <div class="card-alt card--quote quote" v-for="quote in quotes" :key="quote.id">
      <div class="quotes__col-full">
        <label class="pretext">{{ quote.name }}</label>
      </div>
      <div class="quotes__col"><label class="pretext">Date</label><span class="ts--callout no--margin-b quote__date">{{ Format.asDate(quote.createdAt) }}</span></div>
      <div class="quotes__col"><label class="pretext">Quote #</label><span class="ts--callout no--margin-b quote__date">{{ quote.id }}</span></div> 
      <div>
        <button class="quote__button--add-to-cart" @click="doAddToCart(quote.id)" :disabled="isAddingToCart">
          <!-- Add to Cart → -->
          {{ isAddingToCart ? 'Adding...' : 'Add to Cart →' }}
        </button>
        <b-link class="quote__button quote_button-view" :href="`/orders/quote?id=${quote.id}`">View Quote →</b-link>
      </div>
    </div>
  </div>  
</template>