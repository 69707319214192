<script setup>
  import { ref, computed } from 'vue'
  import { useBackend } from '@/composables/Backend';
  import { useCurrentUser } from '@/composables/CurrentUser';
  import QuoteList from '@/components/QuoteList.vue';

  const backend = useBackend();
  const currentUser = useCurrentUser(handleLoggedIn, handleNotLoggedIn);  

  const showLoginForm = ref(false);
  const showAccountData = ref(false);

  const tabs = {    
    orders: 'orders',
    quotes: 'quotes',
    account: 'account'
  };
  const currentTab = ref(tabs.orders);

  const accountInfo = {
    isLoading: ref(false),
    error: ref(''),
    newFirstName: ref(''),
    newLastName: ref(''),
    newEmail: ref(''),
    doUpdate: () => {
      accountInfo.isLoading.value = true;

      currentUser.doUpdate(
        accountInfo.newFirstName.value, 
        accountInfo.newLastName.value, 
        accountInfo.newEmail.value, 
        (data) => {
          accountInfo.newFirstName.value = data.firstName;
          accountInfo.newLastName.value = data.lastName;
          accountInfo.newEmail.value = data.email;

          accountInfo.isLoading.value = false;
        },
        (error) => { 
          accountInfo.error.value = error
          accountInfo.isLoading.value = false;
        });
    }
  };

  const orders = {
    isLoading: ref(false),
    error: ref(''),
    data: ref([])
  };

  const login = {
    isLoading: ref(false),
    error: ref(''),   
    hasError: computed(() => {
      return login.error.value.length > 0;
    }),
    username: ref(''),
    password: ref(''),
    doLogin: () => {
      login.isLoading.value = true;

      currentUser.doLogin(
        login.username.value, 
        login.password.value, 
        (data) => {
          // data
          accountInfo.newFirstName.value = data.firstName;
          accountInfo.newLastName.value = data.lastName;
          accountInfo.newEmail.value = data.email;

          login.password.value = '';
          login.isLoading.value = false;

          handleLoggedIn(data);
        },
        (error) => {
          login.error.value = error;          
          login.password.value = '';
          login.isLoading.value = false;
        });
    },
    doLogout: () => {
      currentUser.doLogout(() => {
        window.location.href = '/';
      });
    }
  }

  const passwordChange = {
    isLoading: ref(false),
    error: ref(''),
    currentPassword: ref(''),
    newPassword: ref(''),
    confirmPassword: ref(''),
    doChange: () => {

    }
  }

  function handleNotLoggedIn() {
    showLoginForm.value = true;
    showAccountData.value = false;
  }
  
  function handleLoggedIn(data) {
    showLoginForm.value = false;
    showAccountData.value = true;

    // setup customer information change form
    accountInfo.newFirstName.value = data.firstName.value;
    accountInfo.newLastName.value = data.lastName.value;
    accountInfo.newEmail.value = data.email.value;

    // get orders
    orders.isLoading.value = true;
    backend.getOrderList()
      .then(ordersResult => {
        if (ordersResult) {
          orders.data.value = ordersResult.orders;
          orders.isLoading.value = false;
        }
      });
  }
</script>

<template>
  <div class="container my-account">
    <div v-if="showLoginForm" class="row section--login">
      <div class="col">
        <h2>Welcome back!</h2>
        <div class="dashed-divider"></div>
        <p class="no--margin-b">
          <a href="/create-account">
            Don't have an account yet? ‍Sign up today for:
          </a>
        </p>
        <h3 class="regular no--margin-b">
          - A faster checkout experience<br />
          - Order status and history<br />
          - Easy access to custom quotes<br />
        </h3>
      </div>
      <div class="col">
        <div class="card card-alt load-2">
          <b-form @submit="login.doLogin">
            <h3>Sign in:</h3>
            <div v-if="login.error.value" class="alert alert-danger">{{ login.error.value }}</div>
            <b-form-input class="text-input__field labeled" v-model="login.username.value" type="email" placeholder="Email"></b-form-input>
            <b-form-input class="text-input__field labeled" v-model="login.password.value" type="password" placeholder="Password"></b-form-input>
            <b-button class="button col--1-of-4 first" type="submit">Sign In →</b-button>
            <b-link class="reset-password--trigger link" href="/password/reset">Forgot Password?</b-link>
          </b-form>
        </div>
      </div>
    </div>

    <div v-if="showAccountData">      
      <div class="section section--pushed">
        <div class="header__title-bar no--border">
          <div class="col-md-8 col-xs-12">
            <h2 class="header__title">Welcome back, {{ currentUser.firstName.value }}!</h2>
          </div>
          <!-- <div class="col-md-4 col-xs-12 d-flex justify-content-md-end align-items-end"> -->
          <div class="tabs__menu">
            <b-link class="link link__text" @click="login.doLogout" href="#">Log Out →</b-link>
          </div>        
        </div>

        <div class="quotes__nav">
          <div class="quotes__nav-item">
            <a href="#" @click="currentTab = tabs.orders" :class="`nav-link ${currentTab==tabs.orders ? 'active': ''}`">Orders</a>
          </div>
          <div class="quotes__nav-item">
            <a href="#" @click="currentTab = tabs.quotes" :class="`nav-link ${currentTab==tabs.quotes ? 'active': ''}`">Quotes</a>
          </div>
          <div class="quotes__nav-item">
            <a href="#" @click="currentTab = tabs.account" :class="`nav-link ${currentTab==tabs.account ? 'active': ''}`">Account</a>
          </div>
        </div>
      
        <div v-if="currentTab == tabs.orders">
          <div class="col">
            <div class="card-alt card--quote quote" v-for="order in orders.data.value" :key="order.orderId">
              <div class="quotes__col"><label class="pretext">Date</label><span class="ts--callout no--margin-b quote__date">{{ order.purchaseDate }}</span></div>
              <div class="quotes__col"><span class="pretext">Order #</span><span class="ts--callout no--margin-b quote__date">{{ order.orderNumber }}</span></div>
              <div class="quotes__col"><span class="pretext">Total Paid</span><span class="ts--callout no--margin-b quote__date">{{ order.totalCostText }}</span></div>
              <a class="quote_button-view" :href="`/orders/details?id=${order.orderId}`">View Order →</a>
            </div>

            <div v-if="orders.data.value && orders.data.value.length == 0" class="card-alt card--stack">
              <div v-if="orders.isLoading.value">
                <p>Loading...</p>
              </div>
              <div v-else-if="orders.data.value.length == 0">
                <p>There aren't any orders to display.</p>
              </div>
            </div>
          </div>
        </div>
        
        <div v-if="currentTab == tabs.quotes">          
          <quote-list></quote-list>
        </div>

        <div class="row" v-if="currentTab == tabs.account">
          <div class="col">
            <div class="card card--quote quotes__customer-card">
              <b-form class="quotes__card-form" @submit="accountInfo.doUpdate">
                <h3>Customer Information</h3>
                <div v-if="accountInfo.error.value" class="alert alert-danger">{{ accountInfo.error.value }}</div>
                <b-form-input class="text-input__field labeled" v-model="accountInfo.newFirstName.value" placeholder="First Name"></b-form-input>
                <b-form-input class="text-input__field labeled" v-model="accountInfo.newLastName.value" placeholder="Last Name"></b-form-input>
                <b-form-input class="text-input__field labeled" v-model="accountInfo.newEmail.value" type="email" placeholder="Email"></b-form-input>
                <a class="quote_button-view" type="submit">Save Changes →</a>
              </b-form>
            </div>            
          </div>
          <div class="col">
            <div class="card card--quote quotes__customer-card">
              <b-form class="quotes__card-form" @submit="passwordChange.doChange">              
                <h3>Change Password</h3>
                <b-form-input class="text-input__field labeled" v-model="passwordChange.currentPassword.value" type="password" placeholder="Current Password"></b-form-input>
                <b-form-input class="text-input__field labeled" v-model="passwordChange.newPassword.value" type="password" placeholder="New Password"></b-form-input>
                <b-form-input class="text-input__field labeled" v-model="passwordChange.confirmPassword.value" type="password" placeholder="Confirm Password"></b-form-input>
                <a class="quote_button-view" type="submit">Update Password →</a>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>